import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchCurrentUser } from './api';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const accessToken = localStorage.getItem('accessToken');

  const fetchUser = async () => {
    try {
      const response = await fetchCurrentUser();
      setUser(response.data);
    } catch (error) {
      // console.error('Failed to fetch user data:', error);
      if (error.response?.status === 401) {
        setUser(null);
      } else {
        window.alert('There was an issue fetching your user data. Some functionalities may be limited. \n \n Please report this to system admin if the issue persists. \n \n Continue using the website.!');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      // console.log('No access token found; setting loading to false immediately.');
      setUser(null);
      setLoading(false);
    } else {
      fetchUser();
    }
  }, [accessToken]);

  return <UserContext.Provider value={{ user, loading, reloadUser: fetchUser }}>{children}</UserContext.Provider>;
};

export const useSpecificAccess = (access) => {
  const { user, loading } = useUser();

  if (loading) return { loading: true, hasPermission: false, details: {} };

  if (!user) return { loading: false, hasPermission: false, details: {} };

  const hasPermission = Boolean(user.accesses?.[access]);
  const details = hasPermission ? user.accesses[access] : {};

  if (Object.keys(details).length === 0) {
    // console.log('No details found');
    return { loading: false, hasPermission: false, details: {} };
  }

  // console.log('Details found');
  return { loading: false, hasPermission, details };
}

export const useSpecificAccessResource = (access, resource) => {
  const { user, loading } = useUser();

  if (loading) return { loading: true, hasPermission: false, details: [] };

  if (!user) return { loading: false, hasPermission: false, details: [] };

  const resourceData = user?.accesses?.[access]?.[resource];
  const hasPermission = Boolean(resourceData && resourceData.actions);
  const details = hasPermission ? resourceData.actions || [] : [];

  if (details.length === 0) {
    return { loading: false, hasPermission: false, details: [] };
  }

  return { loading: false, hasPermission, details };
}
  
export const useAnyAccessResourceAction = (access, resource) => {
  const { user, loading } = useUser();

  if (loading) return { loading: true, hasPermission: false, details: [] };

  if (!user) return { loading: false, hasPermission: false, details: [] };

  const hasPermission = Boolean(user?.accesses?.[access]?.[resource]?.actions?.length);
  const details = hasPermission ? user.accesses[access][resource].actions || [] : [];

  if (details.length === 0) {
    return { loading: false, hasPermission: false, details: [] };
  }

  return { loading: false, hasPermission, details };
};

export const useSpecificAccessResourceAction = (access, resource, action) => {
  const { user, loading } = useUser();

  if (loading) return { loading: true, hasPermission: false, details: [] };

  if (!user) return { loading: false, hasPermission: false, details: [] };

  const actions = user?.accesses?.[access]?.[resource]?.actions || [];
  const hasPermission = actions.includes(action);
  const details = hasPermission ? actions : [];

  if (details.length === 0) {
    return { loading: false, hasPermission: false, details: [] };
  }

  return { loading: false, hasPermission, details };
};
