import { IconHome, IconMap, IconFileText } from '@tabler/icons';

const publicPagesUrl = `https://jcsi.kcjusticeinitiative.org/`;

const navItems = [
  {
    label: 'Home',
    icon: IconHome,
    path: '/',
    isExternal: false, // Internal link
  },
  {
    label: 'Map',
    icon: IconMap,
    path: '/dashboard/map',
    isExternal: false, // Internal link
},
  {
    label: 'Public-Pages',
    icon: IconFileText,
    path: publicPagesUrl,
    isExternal: true, // External link
  },
];

export default navItems;
