import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Protected from './Protected';

const ProxFileUpload = Loadable(lazy(() => import('views/partners/prox/fileuploads')));
const CombatFileUpload = Loadable(lazy(() => import('views/partners/combat/fileuploads')));
const CircuitCourtFileUpload = Loadable(lazy(() => import('views/partners/circuitCourt/fileuploads')));
const JacksonCrimeUnitFileUpload = Loadable(lazy(() => import('views/partners/jacksonCrimeUnit/fileuploads')));
const MunicipalCourtFileUpload = Loadable(lazy(() => import('views/partners/municipalCourt/fileuploads')));

// ==============================|| MAIN ROUTING ||============================== //

const PartnerRoutes = {
  path: '/',
  element: <Protected> <MainLayout /> </Protected>,
  children: [
    {
      path: 'partner',
      children: [
        {
          path: 'prox',
          children: [
            {
              path: 'upload',
              element: <ProxFileUpload />
            }
          ]
        },
        {
          path: 'circuitCourt',
          children: [
            {
              path: 'upload',
              element: <CircuitCourtFileUpload />
            }
          ]
        },
        {
          path: 'combat',
          children: [
            {
              path: 'upload',
              element: <CombatFileUpload />
            }
          ]
        },
        {
          path: 'jacksonCrimeUnit',
          children: [
            {
              path: 'upload',
              element: <JacksonCrimeUnitFileUpload />
            }
          ]
        },
        {
          path: 'municipalCourt',
          children: [
            {
              path: 'upload',
              element: <MunicipalCourtFileUpload />
            }
          ]
        },
      ]
    }
  ]
};

export default PartnerRoutes;
