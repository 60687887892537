import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import HomeContent from 'views/home/HomeContent';
import HomeDashboard from 'views/dashboard/Home';
import DashboardProtectedRoute from 'routes/DashboardProtectedRoute';
import { ACCESSES, RESOURCES } from 'utils/permissions';
import { useSpecificAccess, useAnyAccessResourceAction } from 'context/UserContext';

// dashboard routing
const DashboardFiles = Loadable(lazy(() => import('views/dashboard/dashboard-items/FilesItem')));
const DashboardParticipantInProgramData = Loadable(lazy(() => import('views/dashboard/dashboard-items/ParticipantInProgramDataItem')));
const DashboardEventData = Loadable(lazy(() => import('views/dashboard/dashboard-items/EventDataItems')));
const DashboardCitizenData = Loadable(lazy(() => import('views/dashboard/dashboard-items/CitizenDataItem')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <HomeContent />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          element: (
            <DashboardProtectedRoute permissionHook={useSpecificAccess} permissionArgs={[ACCESSES.DASHBOARD]}>
              <HomeDashboard />
            </DashboardProtectedRoute>
          )
        },
        {
          path: 'files',
          element: (
            <DashboardProtectedRoute permissionHook={useAnyAccessResourceAction} permissionArgs={[ACCESSES.DASHBOARD, RESOURCES.FILES]}>
              <DashboardFiles />
            </DashboardProtectedRoute>
          )
        },
        {
          path: 'participant-in-program-data',
          element: (
            <DashboardProtectedRoute permissionHook={useAnyAccessResourceAction} permissionArgs={[ACCESSES.DASHBOARD, RESOURCES.PARTICIPANT_IN_PROGRAM_DATA]}>
              <DashboardParticipantInProgramData />
            </DashboardProtectedRoute>
          )
        },
        {
          path: 'event-data',
          element: (
            <DashboardProtectedRoute permissionHook={useAnyAccessResourceAction} permissionArgs={[ACCESSES.DASHBOARD, RESOURCES.EVENT_DATA]}>
              <DashboardEventData />
            </DashboardProtectedRoute>
          )
        },
        {
          path: 'citizen-data',
          element: (
            <DashboardProtectedRoute permissionHook={useAnyAccessResourceAction} permissionArgs={[ACCESSES.DASHBOARD, RESOURCES.CITIZEN_DATA]}>
              <DashboardCitizenData />
            </DashboardProtectedRoute>
          )
        }
      ]
    }
  ]
};

export default MainRoutes;
