// import dashboard from './dashboard';
import pages from './pages';
import programs from './programs';
import partners from './partners';
import dashboard from './dashboard';
import { useSpecificAccess } from 'context/UserContext';
import { ACCESSES } from 'utils/permissions';
import { CircularProgress, Box } from '@mui/material';

// Function to check if access token is present in local storage
const isTokenAvailable = () => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken !== null;
};

// Construct menu items based on token availability
const MenuContainer = () => {
  const { loading: accessLoading, hasPermission: hasDashboardAccess } = useSpecificAccess(ACCESSES.DASHBOARD);

  if (accessLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  const getMenuItems = () => {
    if (isTokenAvailable()) {
      const items = [];

      if (hasDashboardAccess) {
        items.push(dashboard);
      }

      if (programs.children.length !== 0) {
        items.push(programs);
      }

      if (partners.children.length !== 0) {
        items.push(partners);
      }

      return { items: items.length > 0 ? items : [pages] };
    } else {
      return { items: [pages] };
    }
  };

  const menuItems = getMenuItems();

  return menuItems;
};

export default MenuContainer;