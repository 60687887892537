import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import { useTheme } from '@mui/material/styles';
import {
  Box,
  IconButton,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  ClickAwayListener,
  Typography,
  Stack,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Transitions from 'ui-component/extended/Transitions';
import navItems from './navItems'; // Import the navigation items

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile check
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const location = useLocation(); // Get current URL

  // Close the dropdown when resizing screen back to desktop
  useEffect(() => {
    if (!isMobile && open) {
      setOpen(false); // Close Popper when switching from mobile to desktop
    }
  }, [isMobile, open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Function to check if the nav item is active
  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path); // For nested paths like `/dashboard/map`
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
        }}
      >
        {isMobile ? (
          <>
            <IconButton ref={anchorRef} onClick={handleToggle}>
              <MoreVertIcon />
            </IconButton>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom-end"
              transition
              disablePortal
              modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
              style={{ zIndex: 1300 }}
            >
              {({ TransitionProps }) => (
                <Transitions in={open} {...TransitionProps}>
                  <Paper
                    sx={{
                      p: 1,
                      minWidth: '180px',
                      borderRadius: '12px',
                      boxShadow: theme.shadows[4],
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box>
                        {/* Render list of nav items dynamically */}
                        <List>
                          {navItems.map((item) => (
                            <ListItem
                              button
                              key={item.label}
                              component={item.isExternal ? 'a' : Link} // Use <a> for external links
                              href={item.isExternal ? item.path : undefined} // Use href for external links
                              to={!item.isExternal ? item.path : undefined} // Use to for internal links
                              target={item.isExternal ? '_blank' : undefined} // Open external links in a new tab
                              rel={item.isExternal ? 'noopener noreferrer' : undefined} // Ensure security for external links
                              sx={{
                                backgroundColor: isActive(item.path) ? theme.palette.action.main : 'inherit',
                                borderRadius: '12px',
                                '&:hover': {
                                  backgroundColor: theme.palette.action.main,
                                  borderRadius: '12px',
                                },
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
                                {item.icon({ strokeWidth: 1.2 })}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography
                                    sx={{
                                      color: theme.palette.text.primary,
                                      fontWeight: isActive(item.path) ? 'bold' : 250, // Highlight current item with bold text
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Transitions>
              )}
            </Popper>
          </>
        ) : (
          <Stack direction="row" spacing={3}>
            {/* Desktop View - render nav items dynamically */}
            {navItems.map((item) => (
              <Typography
                component={item.isExternal ? 'a' : Link}
                href={item.isExternal ? item.path : undefined}
                to={!item.isExternal ? item.path : undefined}
                target={item.isExternal ? '_blank' : undefined}
                rel={item.isExternal ? 'noopener noreferrer' : undefined}
                key={item.label}
                sx={{
                  textDecoration: 'none',
                  color: isActive(item.path) ? theme.palette.primary.main : theme.palette.text.primary,
                  fontWeight: isActive(item.path) ? 'bold' : 'normal',
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {item.label}
              </Typography>
            ))}
          </Stack>
        )}
      </Box>
    </>
  );
};

export default Navbar;
