import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography, Button, Box, CircularProgress } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useNavigate } from 'react-router-dom';
import AlertDialog from 'views/dashboard/components/AlertDialog';
import { useSpecificAccess, useUser } from 'context/UserContext';
import { ACCESSES, RESOURCES } from 'utils/permissions';
import { isMobile } from 'react-device-detect';

const dashboardItems = [
  {
    title: 'File Downloads',
    description: 'Manage and Download the files uploaded by programs.',
    icon: <DescriptionOutlinedIcon fontSize="large" />,
    label: 'Files',
    buttonText: 'Go to Files',
    path: '/dashboard/files',
    color: '#f57c00',
    resource: RESOURCES.FILES,
    comingSoon: false
  },
  {
    title: 'Participant in Program Survey Data',
    description: 'Download and analyze the participant in program survey data of eligible programs.',
    icon: <AssessmentOutlinedIcon fontSize="large" />,
    label: 'Survey Data',
    buttonText: 'Go to Participant in Program Data',
    path: '/dashboard/participant-in-program-data',
    color: '#2e7d32',
    resource: RESOURCES.PARTICIPANT_IN_PROGRAM_DATA,
    comingSoon: false
  },
  {
    title: 'Events Survey Data',
    description: 'Download and analyze the event survey data of eligible programs.',
    icon: <AssessmentOutlinedIcon fontSize="large" />,
    label: 'Survey Data',
    buttonText: 'Go to Events Data',
    path: '/dashboard/event-data',
    color: '#303f9f',
    resource: RESOURCES.EVENT_DATA,
    comingSoon: false
  },
  {
    title: 'Citizens Survey Data',
    description: 'Download and analyze the citizen survey data of eligible programs.',
    icon: <AssessmentOutlinedIcon fontSize="large" />,
    label: 'Survey Data',
    buttonText: 'Go to Citizens Data',
    path: '/dashboard/citizen-data',
    color: '#d32f2f',
    resource: RESOURCES.CITIZEN_DATA,
    comingSoon: false
  },
  {
    title: 'User Management',
    description: 'Manage all users, update profiles, and control program access.',
    icon: <PeopleAltOutlinedIcon fontSize="large" />,
    label: 'Users',
    buttonText: 'Go to User Management',
    path: '/dashboard/users',
    color: '#1976d2',
    resource: RESOURCES.USERS,
    comingSoon: true
  }
];

export default function HomeDashboard() {
  const navigate = useNavigate();
  const { loading: userLoading } = useUser();
  const [error, setError] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [authorizedItems, setAuthorizedItems] = useState([]);

  const { loading: accessLoading, hasPermission: hasDashboardAccess, details: accessibleResources } = useSpecificAccess(ACCESSES.DASHBOARD);

  useEffect(() => {
    if (userLoading || accessLoading) return;

    if (hasDashboardAccess) {
      const itemsWithPermissions = dashboardItems.filter((item) => accessibleResources?.[item.resource]);
      setAuthorizedItems(itemsWithPermissions);
      if (itemsWithPermissions.length === 0) {
        setError('You do not have access to any dashboard items. Contact the admin to request access.');
        setOpenErrorDialog(true);
      }
    }
  }, [userLoading, accessLoading, hasDashboardAccess, accessibleResources]);

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
    setError(null);
  };

  if (userLoading || accessLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, maxWidth: '1200px', margin: 'auto' }}>
      {openErrorDialog && (
        <AlertDialog
          open={openErrorDialog}
          onClose={handleCloseErrorDialog}
          title="No Access"
          message={error || 'You do not have access to any dashboard items. Contact the admin to request access.'}
        />
      )}

      {authorizedItems.length > 0 && (
        <Grid container spacing={{ xs: 2, sm: 3 }} justifyContent="center">
          {authorizedItems.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
              <Card
                sx={{
                  textAlign: 'center',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: isMobile ? 'none' : 'translateY(-10px)',
                    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.2)'
                  },
                  boxShadow: '0 6px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '16px',
                  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
                  maxWidth: 350,
                  margin: 'auto',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
                elevation={3}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mb: 2,
                      width: 60,
                      height: 60,
                      margin: 'auto',
                      borderRadius: '50%',
                      backgroundColor: item.color + '20'
                    }}
                  >
                    {React.cloneElement(item.icon, { style: { color: item.color } })}
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 3,
                      fontWeight: 'bold',
                      color: 'text.primary',
                      fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem', lg: '1.4rem' }
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1, mt: 3 }}>
                    {item.description}
                  </Typography>
                </CardContent>
                <Box sx={{ p: 2 }}>
                  <Button
                    disabled={item.comingSoon}
                    variant="contained"
                    fullWidth
                    onClick={() => navigate(item.path)}
                    sx={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      background: item.comingSoon ? '#ccc' : `linear-gradient(45deg, ${item.color}, ${item.color}CC)`,
                      transition: 'background 0.3s, box-shadow 0.3s',
                      boxShadow: item.comingSoon ? 'none' : '0 4px 10px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        background: item.comingSoon ? '#ccc' : `linear-gradient(45deg, ${item.color}CC, ${item.color}FF)`,
                        boxShadow: item.comingSoon ? 'none' : '0 6px 14px rgba(0, 0, 0, 0.2)'
                      }
                    }}
                    aria-label={item.buttonText}
                  >
                    {item.comingSoon ? 'Coming Soon' : item.buttonText}
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
