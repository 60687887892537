import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardMap = Loadable(lazy(() => import('views/dashboard/Map')));

// ==============================|| MAIN ROUTING ||============================== //

const DashboardMapRoutes = {
  path: 'dashboard',
  element: <MainLayout />,
  children: [
    {
      path: 'map',
      element: <DashboardMap />
    }
  ]
};

export default DashboardMapRoutes;
