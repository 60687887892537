const pages = {
  id: 'pages',
  title: 'Menu',
  type: 'group',
  children: [
  {
      id: 'gome',
      title: 'Home',
      type: 'item',
      url: '/',
    },
    {
      id: 'map',
      title: 'Map',
      type: 'item',
      url: '/dashboard/map',
    }
  ]
};

export default pages;
