import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

function AlertDialog({ open, onClose, title, message }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ fontSize: '1.5rem' }}>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
