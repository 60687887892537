import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as NotFoundImage } from 'assets/images/404Error.svg';
import Button from '@mui/material/Button';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      <NotFoundImage style={{ width: '100%', maxWidth: '400px', marginBottom: '1rem' }} />
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <Button component={Link} to="/" variant="contained" color="primary" style={{ marginTop: '1rem' }}>
        Go back to Home
      </Button>
    </div>
  );
};

export default NotFound;
