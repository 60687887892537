import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ProgramRoutes from './ProgramRoutes';
import PartnerRoutes from './PartnerRoutes';
import DashboardMapRoutes from './DashboardMapRoutes';
import NotFound from 'views/pages/errors/NotFound';
import MainLayout from 'layout/MainLayout';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    DashboardMapRoutes,
    AuthenticationRoutes,
    ProgramRoutes,
    PartnerRoutes,
    {
      path: '*',
      element: <MainLayout />,
      children: [
        {
          path: '*',
          element: <NotFound />
        }
      ]
    }
  ]);
}
