import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import NotFound from 'views/pages/errors/NotFound';
import { useUser } from 'context/UserContext';

const DashboardProtectedRoute = ({ children, permissionHook, permissionArgs = [] }) => {
  const { loading: userLoading, user } = useUser();
  const { loading: permissionLoading, hasPermission: hasPermission} = permissionHook(...permissionArgs);

  const isLoading = userLoading || permissionLoading;

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user || !hasPermission) {
    return <NotFound />;
  }

  return children;
};

export default DashboardProtectedRoute;
