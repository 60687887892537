export const ACCESSES = {
  DASHBOARD: 'dashboard',
  // Add as needed
}

export const RESOURCES = {
  FILES: 'files',
  USERS: 'users',
  PARTICIPANT_IN_PROGRAM_DATA: 'participant_in_program_data'
  // Add as needed
};

export const ACTIONS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  DOWNLOAD: 'download'
  // Add as needed
};

// There must be insync with the backend permissions and their respective resources

export default { ACTIONS , RESOURCES, ACCESSES };
