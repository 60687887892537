import axios from 'axios';

const host = process.env.REACT_APP_API_HOST || 'localhost';
const port = process.env.REACT_APP_API_PORT || '4747';
const protocol = process.env.REACT_APP_API_PROTOCOL || 'http';
const baseURL = `${protocol}://${host}:${port}/api`;

const apiClient = axios.create({ baseURL });

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const fetchCurrentUser = () => apiClient.get('/current_user');