
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Protected from 'routes/Protected';
// import MinimalLayout from 'layout/MinimalLayout';

// dashboard routing
const SecondChanceForm = Loadable(lazy(() => import('views/Forms/SecondChance/RegistrationFormMain')));
const SecondChanceTable = Loadable(lazy(() => import('views/Forms/SecondChance/DataTable')));
const SecondChanceFileUpload = Loadable(lazy(() => import('views/Forms/SecondChance/FileUpload')));







const CCFBlueValleyCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueValley/Citizen/RegistrationFormMain')));
const CCFBlueValleyCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueValley/Citizen/DataTable')));
const CCFBlueValleyLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueValley/Leader/RegistrationFormMain')));
const CCFBlueValleyLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueValley/Leader/DataTable')));
const CCFBlueValleyEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueValley/Event/RegistrationFormMain')));
const CCFBlueValleyEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueValley/Event/DataTable')));
const CCFBlueValleyFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueValley/FileUpload')));

const CCFBlueHillsCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueHills/Citizen/RegistrationFormMain')));
const CCFBlueHillsCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueHills/Citizen/DataTable')));
const CCFBlueHillsLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueHills/Leader/RegistrationFormMain')));
const CCFBlueHillsLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueHills/Leader/DataTable')));
const CCFBlueHillsEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueHills/Event/RegistrationFormMain')));
const CCFBlueHillsEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueHills/Event/DataTable')));
const CCFBlueHillsFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BlueHills/FileUpload')));

const CCFBostonHeightsAndMountHopeCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BostonHeightsAndMountHope/Citizen/RegistrationFormMain')));
const CCFBostonHeightsAndMountHopeCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BostonHeightsAndMountHope/Citizen/DataTable')));
const CCFBostonHeightsAndMountHopeLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BostonHeightsAndMountHope/Leader/RegistrationFormMain')));
const CCFBostonHeightsAndMountHopeLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BostonHeightsAndMountHope/Leader/DataTable')));
const CCFBostonHeightsAndMountHopeEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BostonHeightsAndMountHope/Event/RegistrationFormMain')));
const CCFBostonHeightsAndMountHopeEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BostonHeightsAndMountHope/Event/DataTable')));
const CCFBostonHeightsAndMountHopeFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/BostonHeightsAndMountHope/FileUpload')));

const CCFColumbusParkCommunityCouncilCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ColumbusParkCommunityCouncil/Citizen/RegistrationFormMain')));
const CCFColumbusParkCommunityCouncilCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ColumbusParkCommunityCouncil/Citizen/DataTable')));
const CCFColumbusParkCommunityCouncilLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ColumbusParkCommunityCouncil/Leader/RegistrationFormMain')));
const CCFColumbusParkCommunityCouncilLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ColumbusParkCommunityCouncil/Leader/DataTable')));
const CCFColumbusParkCommunityCouncilEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ColumbusParkCommunityCouncil/Event/RegistrationFormMain')));
const CCFColumbusParkCommunityCouncilEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ColumbusParkCommunityCouncil/Event/DataTable')));
const CCFColumbusParkCommunityCouncilUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ColumbusParkCommunityCouncil/FileUpload')));

const CCFDunbarCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Dunbar/Citizen/RegistrationFormMain')));
const CCFDunbarCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Dunbar/Citizen/DataTable')));
const CCFDunbarLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Dunbar/Leader/RegistrationFormMain')));
const CCFDunbarLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Dunbar/Leader/DataTable')));
const CCFDunbarEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Dunbar/Event/RegistrationFormMain')));
const CCFDunbarEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Dunbar/Event/DataTable')));
const CCFDunbarFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Dunbar/FileUpload')));

const CCFEast23rdStPacCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/East23rdStPac/Citizen/RegistrationFormMain')));
const CCFEast23rdStPacCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/East23rdStPac/Citizen/DataTable')));
const CCFEast23rdStPacLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/East23rdStPac/Leader/RegistrationFormMain')));
const CCFEast23rdStPacLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/East23rdStPac/Leader/DataTable')));
const CCFEast23rdStPacEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/East23rdStPac/Event/RegistrationFormMain')));
const CCFEast23rdStPacEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/East23rdStPac/Event/DataTable')));
const CCFEast23rdStPacFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/East23rdStPac/FileUpload')));

const CCFLykinsCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Lykins/Citizen/RegistrationFormMain')));
const CCFLykinsCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Lykins/Citizen/DataTable')));
const CCFLykinsLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Lykins/Leader/RegistrationFormMain')));
const CCFLykinsLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Lykins/Leader/DataTable')));
const CCFLykinsEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Lykins/Event/RegistrationFormMain')));
const CCFLykinsEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Lykins/Event/DataTable')));
const CCFLykinsFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Lykins/FileUpload')));

const CCFMarlboroughCommunityCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/MarlboroughCommunity/Citizen/RegistrationFormMain')));
const CCFMarlboroughCommunityCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/MarlboroughCommunity/Citizen/DataTable')));
const CCFMarlboroughCommunityLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/MarlboroughCommunity/Leader/RegistrationFormMain')));
const CCFMarlboroughCommunityLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/MarlboroughCommunity/Leader/DataTable')));
const CCFMarlboroughCommunityEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/MarlboroughCommunity/Event/RegistrationFormMain')));
const CCFMarlboroughCommunityEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/MarlboroughCommunity/Event/DataTable')));
const CCFMarlboroughCommunityFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/MarlboroughCommunity/FileUpload')));

const CCFPaseoWestCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PaseoWest/Citizen/RegistrationFormMain')));
const CCFPaseoWestCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PaseoWest/Citizen/DataTable')));
const CCFPaseoWestLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PaseoWest/Leader/RegistrationFormMain')));
const CCFPaseoWestLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PaseoWest/Leader/DataTable')));
const CCFPaseoWestEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PaseoWest/Event/RegistrationFormMain')));
const CCFPaseoWestEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PaseoWest/Event/DataTable')));
const CCFPaseoWestFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PaseoWest/FileUpload')));

const CCFPendletonHeightsNACitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PendletonHeightsNA/Citizen/RegistrationFormMain')));
const CCFPendletonHeightsNACitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PendletonHeightsNA/Citizen/DataTable')));
const CCFPendletonHeightsNALeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PendletonHeightsNA/Leader/RegistrationFormMain')));
const CCFPendletonHeightsNALeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PendletonHeightsNA/Leader/DataTable')));
const CCFPendletonHeightsNAEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PendletonHeightsNA/Event/RegistrationFormMain')));
const CCFPendletonHeightsNAEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PendletonHeightsNA/Event/DataTable')));
const CCFPendletonHeightsNAFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/PendletonHeightsNA/FileUpload')));

const CCFSantaFeAreaCouncilCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/SantaFeAreaCouncil/Citizen/RegistrationFormMain')));
const CCFSantaFeAreaCouncilCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/SantaFeAreaCouncil/Citizen/DataTable')));
const CCFSantaFeAreaCouncilLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/SantaFeAreaCouncil/Leader/RegistrationFormMain')));
const CCFSantaFeAreaCouncilLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/SantaFeAreaCouncil/Leader/DataTable')));
const CCFSantaFeAreaCouncilEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/SantaFeAreaCouncil/Event/RegistrationFormMain')));
const CCFSantaFeAreaCouncilEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/SantaFeAreaCouncil/Event/DataTable')));
const CCFSantaFeAreaCouncilFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/SantaFeAreaCouncil/FileUpload')));

const CCFTownForkCreekCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TownForkCreek/Citizen/RegistrationFormMain')));
const CCFTownForkCreekCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TownForkCreek/Citizen/DataTable')));
const CCFTownForkCreekLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TownForkCreek/Leader/RegistrationFormMain')));
const CCFTownForkCreekLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TownForkCreek/Leader/DataTable')));
const CCFTownForkCreekEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TownForkCreek/Event/RegistrationFormMain')));
const CCFTownForkCreekEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TownForkCreek/Event/DataTable')));
const CCFTownForkCreekFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TownForkCreek/FileUpload')));

const CCFViewHighDriveNorthCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ViewHighDriveNorth/Citizen/RegistrationFormMain')));
const CCFViewHighDriveNorthCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ViewHighDriveNorth/Citizen/DataTable')));
const CCFViewHighDriveNorthLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ViewHighDriveNorth/Leader/RegistrationFormMain')));
const CCFViewHighDriveNorthLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ViewHighDriveNorth/Leader/DataTable')));
const CCFViewHighDriveNorthEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ViewHighDriveNorth/Event/RegistrationFormMain')));
const CCFViewHighDriveNorthEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ViewHighDriveNorth/Event/DataTable')));
const CCFViewHighDriveNorthFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/ViewHighDriveNorth/FileUpload')));

const CCFVineyardCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Vineyard/Citizen/RegistrationFormMain')));
const CCFVineyardCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Vineyard/Citizen/DataTable')));
const CCFVineyardLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Vineyard/Leader/RegistrationFormMain')));
const CCFVineyardLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Vineyard/Leader/DataTable')));
const CCFVineyardEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Vineyard/Event/RegistrationFormMain')));
const CCFVineyardEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Vineyard/Event/DataTable')));
const CCFVineyardFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/Vineyard/FileUpload')));

const CCFWardParkwayHomesAssociationCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WardParkwayHomesAssociation/Citizen/RegistrationFormMain')));
const CCFWardParkwayHomesAssociationCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WardParkwayHomesAssociation/Citizen/DataTable')));
const CCFWardParkwayHomesAssociationLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WardParkwayHomesAssociation/Leader/RegistrationFormMain')));
const CCFWardParkwayHomesAssociationLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WardParkwayHomesAssociation/Leader/DataTable')));
const CCFWardParkwayHomesAssociationEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WardParkwayHomesAssociation/Event/RegistrationFormMain')));
const CCFWardParkwayHomesAssociationEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WardParkwayHomesAssociation/Event/DataTable')));
const CCFWardParkwayHomesAssociationFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WardParkwayHomesAssociation/FileUpload')));

const CCFWashingtonWheatleyCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WashingtonWheatley/Citizen/RegistrationFormMain')));
const CCFWashingtonWheatleyCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WashingtonWheatley/Citizen/DataTable')));
const CCFWashingtonWheatleyLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WashingtonWheatley/Leader/RegistrationFormMain')));
const CCFWashingtonWheatleyLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WashingtonWheatley/Leader/DataTable')));
const CCFWashingtonWheatleyEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WashingtonWheatley/Event/RegistrationFormMain')));
const CCFWashingtonWheatleyEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WashingtonWheatley/Event/DataTable')));
const CCFWashingtonWheatleyFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/WashingtonWheatley/FileUpload')));

const CCFFoxtownEastCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/FoxtownEast/Citizen/RegistrationFormMain')));
const CCFFoxtownEastCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/FoxtownEast/Citizen/DataTable')));
const CCFFoxtownEastLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/FoxtownEast/Leader/RegistrationFormMain')));
const CCFFoxtownEastLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/FoxtownEast/Leader/DataTable')));
const CCFFoxtownEastEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/FoxtownEast/Event/RegistrationFormMain')));
const CCFFoxtownEastEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/FoxtownEast/Event/DataTable')));
const CCFFoxtownEastFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/FoxtownEast/FileUpload')));

const CCFTriBlenheimCitizenForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TriBlenheim/Citizen/RegistrationFormMain')));
const CCFTriBlenheimCitizenTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TriBlenheim/Citizen/DataTable')));
const CCFTriBlenheimLeaderForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TriBlenheim/Leader/RegistrationFormMain')));
const CCFTriBlenheimLeaderTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TriBlenheim/Leader/DataTable')));
const CCFTriBlenheimEventForm = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TriBlenheim/Event/RegistrationFormMain')));
const CCFTriBlenheimEventTable = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TriBlenheim/Event/DataTable')));
const CCFTriBlenheimFileUpload = Loadable(lazy(() => import('views/Forms/CapitalCommunityFund/TriBlenheim/FileUpload')));





const KCCommonGoodGirlScoutsDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/GirlScouts/DataTable')));
const KCCommonGoodGirlScoutsEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/GirlScouts/RegistrationFormMain')));
const KCCommonGoodGirlScoutsFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/GirlScouts/FileUpload')));

const KCCommonGoodCenterForConflictResolutionDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/CenterForConflictResolution/DataTable')));
const KCCommonGoodCenterForConflictResolutionEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/CenterForConflictResolution/RegistrationFormMain')));
const KCCommonGoodCenterForConflictResolutionFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/CenterForConflictResolution/FileUpload')));

const KCCommonGoodFullEmploymentCouncilDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/FullEmploymentCouncil/DataTable')));
const KCCommonGoodFullEmploymentCouncilEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/FullEmploymentCouncil/RegistrationFormMain')));
const KCCommonGoodFullEmploymentCouncilFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/FullEmploymentCouncil/FileUpload')));

const KCCommonGoodGratefulEducationFoundationDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/GratefulEducationFoundation/DataTable')));
const KCCommonGoodGratefulEducationFoundationEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/GratefulEducationFoundation/RegistrationFormMain')));
const KCCommonGoodGratefulEducationFoundationFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/GratefulEducationFoundation/FileUpload')));

const KCCommonGoodKCFreedomSchoolsDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/KCFreedomSchools/DataTable')));
const KCCommonGoodKCFreedomSchoolsEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/KCFreedomSchools/RegistrationFormMain')));
const KCCommonGoodKCFreedomSchoolsFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/KCFreedomSchools/FileUpload')));

const KCCommonGoodLyriksInstitutionDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/LyriksInstitution/DataTable')));
const KCCommonGoodLyriksInstitutionEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/LyriksInstitution/RegistrationFormMain')));
const KCCommonGoodLyriksInstitutionFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/LyriksInstitution/FileUpload')));

const KCCommonGoodJamisonCMETempleDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/JamisonCMETemple/DataTable')));
const KCCommonGoodJamisonCMETempleEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/JamisonCMETemple/RegistrationFormMain')));
const KCCommonGoodJamisonCMETempleFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/JamisonCMETemple/FileUpload')));

const KCCommonGoodTheHopeCenterDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/TheHopeCenter/DataTable')));
const KCCommonGoodTheHopeCenterEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/TheHopeCenter/RegistrationFormMain')));
const KCCommonGoodTheHopeCenterFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/TheHopeCenter/FileUpload')));

const KCCommonGoodUrbanRangersDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/UrbanRangers/DataTable')));
const KCCommonGoodUrbanRangersEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/UrbanRangers/RegistrationFormMain')));
const KCCommonGoodUrbanRangersFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/UrbanRangers/FileUpload')));

const KCCommonGoodYouthAmbassadorsDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/YouthAmbassadors/DataTable')));
const KCCommonGoodYouthAmbassadorsEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/YouthAmbassadors/RegistrationFormMain')));
const KCCommonGoodYouthAmbassadorsFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/YouthAmbassadors/FileUpload')));

const KCCommonGoodYouthGuidanceDataTable = Loadable(lazy(() => import('views/Forms/KCCommonGood/YouthGuidance/DataTable')));
const KCCommonGoodYouthGuidanceEntryForm = Loadable(lazy(() => import('views/Forms/KCCommonGood/YouthGuidance/RegistrationFormMain')));
const KCCommonGoodYouthGuidanceFileUpload = Loadable(lazy(() => import('views/Forms/KCCommonGood/YouthGuidance/FileUpload')));





const IBuildDataTable = Loadable(lazy(() => import('views/Forms/IBuild/DataTable')));
const IBuildEntryForm = Loadable(lazy(() => import('views/Forms/IBuild/RegistrationFormMain')));
const IBuildFileUpload = Loadable(lazy(() => import('views/Forms/IBuild/FileUpload')));



const MentalHealthJourneyToNewLifeDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/JourneyToNewLife/DataTable')));
const MentalHealthJourneyToNewLifeEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/JourneyToNewLife/RegistrationFormMain')));
const MentalHealthJourneyToNewLifeFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/JourneyToNewLife/FileUpload')));

const MentalHealthPiecesPeacesDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/PiecesPeaces/DataTable')));
const MentalHealthPiecesPeacesEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/PiecesPeaces/RegistrationFormMain')));
const MentalHealthPiecesPeacesFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/PiecesPeaces/FileUpload')));

const MentalHealthTotalManCDCDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/TotalManCDC/DataTable')));
const MentalHealthTotalManCDCEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/TotalManCDC/RegistrationFormMain')));
const MentalHealthTotalManCDCFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/TotalManCDC/FileUpload')));

const MentalHealthCCONDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/CCON/DataTable')));
const MentalHealthCCONEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/CCON/RegistrationFormMain')));
const MentalHealthCCONFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/CCON/FileUpload')));

const MentalHealthJourneyToNewLifeCaseDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/JourneyToNewLifeCase/DataTable')));
const MentalHealthJourneyToNewLifeCaseEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/JourneyToNewLifeCase/RegistrationFormMain')));
const MentalHealthJourneyToNewLifeCaseFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/JourneyToNewLifeCase/FileUpload')));

const MentalHealthLyriksInstitutionCaseDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/LyriksInstitutionCase/DataTable')));
const MentalHealthLyriksInstitutionCaseEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/LyriksInstitutionCase/RegistrationFormMain')));
const MentalHealthLyriksInstitutionCaseFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/LyriksInstitutionCase/FileUpload')));

const MentalHealthKCPublicLibraryPatronDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/KCPublicLibraryPatron/DataTable')));
const MentalHealthKCPublicLibraryPatronEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/KCPublicLibraryPatron/RegistrationFormMain')));
const MentalHealthKCPublicLibraryPatronFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/KCPublicLibraryPatron/FileUpload')));

const MentalHealthKCPublicLibraryStaffDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/KCPublicLibraryStaff/DataTable')));
const MentalHealthKCPublicLibraryStaffEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/KCPublicLibraryStaff/RegistrationFormMain')));
const MentalHealthKCPublicLibraryStaffFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/KCPublicLibraryStaff/FileUpload')));

const MentalHealthCampChoiceAHSaturdayDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/CampChoiceAHSaturday/DataTable')));
const MentalHealthCampChoiceAHSaturdayEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/CampChoiceAHSaturday/RegistrationFormMain')));
const MentalHealthCampChoiceAHSaturdayFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/CampChoiceAHSaturday/FileUpload')));

const MentalHealthCampChoiceRetreatAndRevivalDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/CampChoiceRetreatAndRevival/DataTable')));
const MentalHealthCampChoiceRetreatAndRevivalEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/CampChoiceRetreatAndRevival/RegistrationFormMain')));
const MentalHealthCampChoiceRetreatAndRevivalFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/CampChoiceRetreatAndRevival/FileUpload')));

const MentalHealthCCONTheLOTDataTable = Loadable(lazy(() => import('views/Forms/MentalHealth/CCON (The LOT)/DataTable')));
const MentalHealthCCONTheLOTEntryForm = Loadable(lazy(() => import('views/Forms/MentalHealth/CCON (The LOT)/RegistrationFormMain')));
const MentalHealthCCONTheLOTFileUpload = Loadable(lazy(() => import('views/Forms/MentalHealth/CCON (The LOT)/FileUpload')));



// ==============================|| MAIN ROUTING ||============================== //

const ProgramRoutes = {
  path: '/',
  element: <Protected> <MainLayout /> </Protected>,
  children: [
    {
      path: 'program',
      children: [
        {
          path: 'secondChance',
          children: [
            {
              path: 'dataTable',
              element: <SecondChanceTable />
            },
            {
              path: 'entryForm',
              element: <SecondChanceForm />
            },
            {
              path: 'fileUpload',
              element: <SecondChanceFileUpload />
            }
          ]
        },
        {
          path: 'capitalCommunityFund',
          children: [
            {
              path: 'blueValley',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBlueValleyCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBlueValleyCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBlueValleyLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBlueValleyLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBlueValleyEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBlueValleyEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFBlueValleyFileUpload />
                },
              ]
            },
            {
              path: 'blueHills',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBlueHillsCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBlueHillsCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBlueHillsLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBlueHillsLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBlueHillsEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBlueHillsEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFBlueHillsFileUpload />
                },
              ]
            },
            {
              path: 'bostonHeightsAndMountHope',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBostonHeightsAndMountHopeCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBostonHeightsAndMountHopeCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBostonHeightsAndMountHopeLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBostonHeightsAndMountHopeLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFBostonHeightsAndMountHopeEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFBostonHeightsAndMountHopeEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFBostonHeightsAndMountHopeFileUpload />
                },
              ]
            },
            {
              path: 'columbusParkCommunityCouncil',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFColumbusParkCommunityCouncilCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFColumbusParkCommunityCouncilCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFColumbusParkCommunityCouncilLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFColumbusParkCommunityCouncilLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFColumbusParkCommunityCouncilEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFColumbusParkCommunityCouncilEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFColumbusParkCommunityCouncilUpload />
                },
              ]
            },
            {
              path: 'dunbar',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFDunbarCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFDunbarCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFDunbarLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFDunbarLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFDunbarEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFDunbarEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFDunbarFileUpload />
                },
              ]
            },
            {
              path: 'east23rdStPac',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFEast23rdStPacCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFEast23rdStPacCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFEast23rdStPacLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFEast23rdStPacLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFEast23rdStPacEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFEast23rdStPacEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFEast23rdStPacFileUpload />
                },
              ]
            },
            {
              path: 'lykins',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFLykinsCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFLykinsCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFLykinsLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFLykinsLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFLykinsEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFLykinsEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFLykinsFileUpload />
                },
              ]
            },
            {
              path: 'marlboroughCommunity',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFMarlboroughCommunityCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFMarlboroughCommunityCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFMarlboroughCommunityLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFMarlboroughCommunityLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFMarlboroughCommunityEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFMarlboroughCommunityEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFMarlboroughCommunityFileUpload />
                },
              ]
            },
            {
              path: 'paseoWest',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFPaseoWestCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFPaseoWestCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFPaseoWestLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFPaseoWestLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFPaseoWestEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFPaseoWestEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFPaseoWestFileUpload />
                },
              ]
            },
            {
              path: 'pendletonHeightsNA',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFPendletonHeightsNACitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFPendletonHeightsNACitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFPendletonHeightsNALeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFPendletonHeightsNALeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFPendletonHeightsNAEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFPendletonHeightsNAEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFPendletonHeightsNAFileUpload />
                },
              ]
            },
            {
              path: 'santaFeAreaCouncil',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFSantaFeAreaCouncilCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFSantaFeAreaCouncilCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFSantaFeAreaCouncilLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFSantaFeAreaCouncilLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFSantaFeAreaCouncilEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFSantaFeAreaCouncilEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFSantaFeAreaCouncilFileUpload />
                },
              ]
            },
            {
              path: 'townForkCreek',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFTownForkCreekCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFTownForkCreekCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFTownForkCreekLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFTownForkCreekLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFTownForkCreekEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFTownForkCreekEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFTownForkCreekFileUpload />
                },
              ]
            },
            {
              path: 'viewHighDriveNorth',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFViewHighDriveNorthCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFViewHighDriveNorthCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFViewHighDriveNorthLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFViewHighDriveNorthLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFViewHighDriveNorthEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFViewHighDriveNorthEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFViewHighDriveNorthFileUpload />
                },
              ]
            },
            {
              path: 'vineyard',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFVineyardCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFVineyardCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFVineyardLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFVineyardLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFVineyardEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFVineyardEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFVineyardFileUpload />
                },
              ]
            },
            {
              path: 'wardParkwayHomesAssociation',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFWardParkwayHomesAssociationCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFWardParkwayHomesAssociationCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFWardParkwayHomesAssociationLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFWardParkwayHomesAssociationLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFWardParkwayHomesAssociationEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFWardParkwayHomesAssociationEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFWardParkwayHomesAssociationFileUpload />
                },
              ]
            },
            {
              path: 'washingtonWheatley',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFWashingtonWheatleyCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFWashingtonWheatleyCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFWashingtonWheatleyLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFWashingtonWheatleyLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFWashingtonWheatleyEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFWashingtonWheatleyEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFWashingtonWheatleyFileUpload />
                },
              ]
            },
            {
              path: 'foxtownEast',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFFoxtownEastCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFFoxtownEastCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFFoxtownEastLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFFoxtownEastLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFFoxtownEastEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFFoxtownEastEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFFoxtownEastFileUpload />
                },
              ]
            },
            {
              path: 'triBlenheim',
              children: [
                {
                  path: 'citizen',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFTriBlenheimCitizenTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFTriBlenheimCitizenForm />
                    },
                  ]
                },
                {
                  path: 'leader',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFTriBlenheimLeaderTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFTriBlenheimLeaderForm />
                    },
                  ]
                },
                {
                  path: 'event',
                  children: [
                    {
                      path: 'dataTable',
                      element: <CCFTriBlenheimEventTable />
                    },
                    {
                      path: 'entryForm',
                      element: <CCFTriBlenheimEventForm />
                    },
                  ]
                },
                {
                  path: 'fileUpload',
                  element: <CCFTriBlenheimFileUpload />
                },
              ]
            },
          ]
        },
        {
          path: 'KCCommonGood',
          children: [
            {
              path: 'GirlScouts',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodGirlScoutsDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodGirlScoutsEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodGirlScoutsFileUpload />
                },
              ]
            },
            {
              path: 'CenterForConflictResolution',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodCenterForConflictResolutionDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodCenterForConflictResolutionEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodCenterForConflictResolutionFileUpload />
                },
              ]
            },
            {
              path: 'FullEmploymentCouncil',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodFullEmploymentCouncilDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodFullEmploymentCouncilEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodFullEmploymentCouncilFileUpload />
                },
              ]
            },
            {
              path: 'GratefulEducationFoundation',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodGratefulEducationFoundationDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodGratefulEducationFoundationEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodGratefulEducationFoundationFileUpload />
                },
              ]
            },
            {
              path: 'KCFreedomSchools',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodKCFreedomSchoolsDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodKCFreedomSchoolsEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodKCFreedomSchoolsFileUpload />
                },
              ]
            },
            {
              path: 'LyriksInstitution',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodLyriksInstitutionDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodLyriksInstitutionEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodLyriksInstitutionFileUpload />
                },
              ]
            },
            {
              path: 'JamisonCMETemple',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodJamisonCMETempleDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodJamisonCMETempleEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodJamisonCMETempleFileUpload />
                },
              ]
            },
            {
              path: 'TheHopeCenter',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodTheHopeCenterDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodTheHopeCenterEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodTheHopeCenterFileUpload />
                },
              ]
            },
            {
              path: 'UrbanRangersCorp',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodUrbanRangersDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodUrbanRangersEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodUrbanRangersFileUpload />
                },
              ]
            },
            {
              path: 'YouthAmbassadors',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodYouthAmbassadorsDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodYouthAmbassadorsEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodYouthAmbassadorsFileUpload />
                },
              ]
            },
            {
              path: 'YouthGuidance',
              children: [
                {
                  path: 'dataTable',
                  element: <KCCommonGoodYouthGuidanceDataTable />
                },
                {
                  path: 'entryForm',
                  element: <KCCommonGoodYouthGuidanceEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <KCCommonGoodYouthGuidanceFileUpload />
                },
              ]
            },
          ]
        },
        {
          path: 'iBuild',
          children: [
            {
              path: 'dataTable',
              element: <IBuildDataTable />
            },
            {
              path: 'EntryForm',
              element: <IBuildEntryForm />
            },
            {
              path: 'fileUpload',
              element: <IBuildFileUpload />
            }
          ]
        },
        {
          path: 'MentalHealth',
          children: [
            {
              path: 'JourneyToNewLife',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthJourneyToNewLifeDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthJourneyToNewLifeEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthJourneyToNewLifeFileUpload />
                },
              ]
            },
            {
              path: 'PiecesPeaces',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthPiecesPeacesDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthPiecesPeacesEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthPiecesPeacesFileUpload />
                },
              ]
            },
            {
              path: 'TotalManCDC',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthTotalManCDCDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthTotalManCDCEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthTotalManCDCFileUpload />
                },
              ]
            },
            {
              path: 'CCON(Calvary)',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthCCONDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthCCONEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthCCONFileUpload />
                },
              ]
            },
            {
              path: 'JourneyToNewLifeCase',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthJourneyToNewLifeCaseDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthJourneyToNewLifeCaseEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthJourneyToNewLifeCaseFileUpload />
                },
              ]
            },
            {
              path: 'LyriksInstitutionCase',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthLyriksInstitutionCaseDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthLyriksInstitutionCaseEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthLyriksInstitutionCaseFileUpload />
                },
              ]
            },
            {
              path: 'KCPublicLibraryPatron',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthKCPublicLibraryPatronDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthKCPublicLibraryPatronEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthKCPublicLibraryPatronFileUpload />
                },
              ]
            },
            {
              path: 'KCPublicLibraryStaff',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthKCPublicLibraryStaffDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthKCPublicLibraryStaffEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthKCPublicLibraryStaffFileUpload />
                },
              ]
            },
            {
              path: 'CampChoiceAHSaturday',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthCampChoiceAHSaturdayDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthCampChoiceAHSaturdayEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthCampChoiceAHSaturdayFileUpload />
                },
              ]
            },
            {
              path: 'CampChoiceRetreatAndRevival',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthCampChoiceRetreatAndRevivalDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthCampChoiceRetreatAndRevivalEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthCampChoiceRetreatAndRevivalFileUpload />
                },
              ]
            },
            {
              path: 'CCON(TheLOT)',
              children: [
                {
                  path: 'dataTable',
                  element: <MentalHealthCCONTheLOTDataTable />
                },
                {
                  path: 'entryForm',
                  element: <MentalHealthCCONTheLOTEntryForm />
                },
                {
                  path: 'fileUpload',
                  element: <MentalHealthCCONTheLOTFileUpload />
                },
              ]
            }
          ]
        },
      ]
    }
  ]
};

export default ProgramRoutes;
